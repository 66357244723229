var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('back-off', {
    attrs: {
      "url": _vm.backUrl,
      "title": "About Us"
    }
  }), _vm._m(0)], 1);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticStyle: {
      "padding-left": "3vw",
      "padding-right": "3vw"
    }
  }, [_c('div', {
    staticStyle: {
      "display": "flex",
      "justify-content": "center"
    }
  }, [_c('h1', {
    staticStyle: {
      "justify-content": "center"
    }
  }, [_vm._v("About Us")])]), _c('div', [_c('h3', {
    staticClass: "heading-h3"
  }, [_c('i', [_vm._v("Thanks for considering Udefine")]), _vm._v(". ")])]), _c('p', [_vm._v(" We are a technology enterprise based on strong image processing technology, design and manufacturing capacity. We are a young, dedicated team always trying our best to bring customers the most positive shopping experience. ")]), _c('p', [_vm._v(" Personalization is what sets us apart from others. At Udefine, we believe that personalization is the most powerful way to express your love to the person you honor and your personal characteristics as well. From birthdays and anniversaries to holiday gifts, we're your number one personalization destination where you can find one-of-a-kind products of high quality with unique designs. ")]), _c('p', [_vm._v(" Your happiness is our top priority for each and every gift we deliver. We love knowing that we play a role in putting smiles on the faces of your loved ones when they receive a special gift made just for them. We're proud to be the pioneer store in the market where you can immediately see a preview of your custom product as you select the specifications. ")]), _c('p', [_vm._v(" With our customer-centric core value, we strongly believe that you'll receive high-quality products and only the best customer service. ")]), _c('p', [_vm._v(" Your feedback is the motivation for us to continue creating new products and bringing you the best shopping experience. ")]), _c('p', [_vm._v(" To maintain the good reputation and user experience of our website, we strictly prohibit the uploading of any illegal and inappropriate images, including pornography, violence, gambling, fraud, and so on. Any violations will result in the cancellation of orders, and we reserve the right to pursue legal action. We also call on all users to participate in monitoring and work together to create a healthy and civilized online environment. ")]), _c('p', [_vm._v(" If you have other questions,please contact us: "), _c('a', {
    attrs: {
      "href": "mailto:udefine@caiseshi.cn",
      "target": "_blank"
    }
  }, [_vm._v(" udefine@caiseshi.cn ")])]), _c('p', [_vm._v(" Thanks for visiting our store and please contact us anytime. ")])]);
}];
export { render, staticRenderFns };